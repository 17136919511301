export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Hilal Saral'
            ]
        ],
        [
            'Oyuncular', [
                'Yeliz Akkaya',
                'Saruhan Hünel',
                'Begüm Kütük',
                'Ayse Bersun Gorica',
                'Yasar Üzer',
                'Gürgen Öz'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Hilal Saral'
            ]
        ],
        [
            'Cast', [
                'Yeliz Akkaya',
                'Saruhan Hünel',
                'Begüm Kütük',
                'Ayse Bersun Gorica',
                'Yasar Üzer',
                'Gürgen Öz'
            ]
        ]
    ]
};