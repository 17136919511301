export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo Gurubu', ['Talat Karagöz', 'Esin Gök', 'Tanhu Sercan Şipka',
                'Evin Çamdoruk', 'Başak Başaran', 'Nazlı Şahin']
        ],
        [
            'YÖNETMENLER', [
                'Mehtap Köroğlu',
                'Irmak Çığ',
                'FulyaYavuzoğlu',
                'Özgür Pak'
            ]
        ],
        [
            'Oyuncular', [
                'Cem Kılıç',
                'Ceyda Tepeliler',
                'Zeynep Dörtkardeşler',
                'Selma Güneri',
                'Fulya Özcan',
                'Bülent Fil',
                'Teoman Mermutlu',
                'Tanya Caziri',
                'Yasar Üzel'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer group', ['Talat Karagöz', 'Esin Gök', 'Tanhu Sercan Şipka',
                'Evin Çamdoruk', 'Başak Başaran', 'Nazlı Şahin']
        ],
        [
            'Directors',  [
                'Mehtap Köroğlu',
                'Irmak Çığ',
                'FulyaYavuzoğlu',
                'Özgür Pak'
            ]
        ],
        [
            'Cast',  [
                'Cem Kılıç',
                'Ceyda Tepeliler',
                'Zeynep Dörtkardeşler',
                'Selma Güneri',
                'Fulya Özcan',
                'Bülent Fil',
                'Teoman Mermutlu',
                'Tanya Caziri',
                'Yasar Üzel'
            ]
        ]
    ]
};