import React from 'react';
import { photos } from '../../utilities/News/News.ts';
import { urlIframe } from '../../utilities/News/NewsUrls.ts';
import Iframe from '../Utilities/Iframe/Iframe';
import './News.css';
export default class News extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            zoomedImg: false
        };
        this.functionZoomImage = this.functionZoomImage.bind(this);
        this.functionCloseImage = this.functionCloseImage.bind(this);
    }
    functionZoomImage (index){
        this.setState({
            zoomedImg: photos[parseInt(index)]
        });
        document.documentElement.style.overflow = "hidden";
    }
    functionCloseImage (){
        this.setState({
            zoomedImg: false
        });
        document.documentElement.style.overflow = "auto";
    }
    render () {
        return (
            <div className="news-container">
                <div className="iframe-wrapper-news">
                    {
                        urlIframe.map((element, index) => {
                            return(
                                <div key={index.toString()} className="iframe-cmbt-news-comp">
                                    <Iframe key={index.toString()} url={element[0]} photo={element[1]}></Iframe>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="image-wrapper-news">
                    {
                        photos.map((photo, index) => {
                            return(
                                <img onClick={(e)=> this.functionZoomImage(index, e)} className="image-newspage" key={index.toString()} src={photo} alt=""></img>
                            )
                        })
                    }
                </div>
                {this.state.zoomedImg &&
                    <div className="background-image-popup">
                        <div className="popup-image-wrapper">
                            <p className="close-me" onClick={(e)=> this.functionCloseImage(e)}> x</p>
                            <img className="popup-image" src={this.state.zoomedImg} alt=""></img>
                        </div>
                    </div>
                }
            </div>
        );
     }
}