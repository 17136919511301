export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Hilal Saral'
            ]
        ],
        [
            'Oyuncular', [
                'Sermin Hürmeric',
                'Orçun Sonat',
                'Saruhan Hünel',
                'Erkan Petekkaya',
                'Ahu Türkpence',
                'Metin Yildirim',
                'Dilek Pamirtan'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Hilal Saral'
            ]
        ],
        [
            'Cast', [
                'Sermin Hürmeric',
                'Orçun Sonat',
                'Saruhan Hünel',
                'Erkan Petekkaya',
                'Ahu Türkpence',
                'Metin Yildirim',
                'Dilek Pamirtan'
            ]
        ]
    ]
};