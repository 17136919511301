export const info = {
    'TR': `Yetimhanede büyümüş, birbirlerini kaybetmiş üç çocuğun hikayesidir.
    Esmer ile Ali yetimhanede büyüyen ve geçmişlerini bilmeyen iki küçük çocuktur. Esmer ve Ali 6 yaşındayken, yetimhane kapısına bırakılan küçük bebek, iki çocuğun hayatına bambaşka bir renk katar. Küçük Ezel’e adını koyamadıkları bir sevgiyle bağlıdırlar.
    Bu sevgi bağı Ezel’in 6 yaşındayken bir aile tarafından, evlat edinilmesiyle bir anda çözülür. Ezel yeni ailesinin evinde, adı ve tüm dünyası değiştirilmiş bir şekilde yaşamaya başlar.
    Ali ve Esmer bu ayrılığın acısını çıkarmak için yetimhaneyi ateşe verip kaçarlar. Artık evsiz barksız Ezel’siz bir yaşam onları beklemektedir...
    Ezel’in yetimhaneden ayrılması ve Ali ile Esmer’in İstanbul’un karmaşık sokaklarında gözden kaybolmalarının ardından, tam 20 yıl geçer ve yaşam onları hiç beklenmedik bir anda karşı karşıya getirir. 
    Artık üçünün de bambaşka hayatları vardır. 
    20 yıl önce savrulan ve birbirinden bir daha haber alamayan bu üç çocuğun yolları, yıllar sonra yeniden kesişecek ve birbirlerini tanımayan Ali, Esmer ve Ezel, kaderin kendileri için hazırladığı müthiş bir oyunun kurbanı olacaklardır.
    Kaybolan Yıllar, Türk televizyonlarında iki yıl gibi uzun bir süre yayınlandıktan sonra, Orta Doğu’da da çok seyredilmiş, başarısını çekimleri bittikten uzun süre sonra da kendinden söz ettirerek, devam ettirmiştir.`,

    'Eng': `The story of three children who grew up in an orphanage and then lost each other.
    Esmer and Ali are two kids grown up in an orphanage and don’t know their pasts. When Esmer and Ali were 6 years old, there was a baby girl abandoned at the door of the orphanage. This baby girl gave meaninful touch to Ali and Esmer’s life. They attached little Ezel ever so much they could not explain this affection with words.
    This affection was broken up when Ezel was adopted. Ezel started a new life with a new family and a new identity. Ali and Esmer set fire to the orphanage to took revenge for Ezel’s departure. They would have a life without shelter, without home and without Ezel.
    After Ezel’s departure, Ali and Esmer left the orphanage and 20 years later beyond all forecast, the life brings them together. They have three different lives now.
    Those three who were seperated 20 years ago and have not seen each other since then, come across again. But Ali,
    Ezel and Esmer, who don’t recognize each other at first, are about to become victims of the destiny.
    After Kaybolan Yıllar had been aired two years in Turkish tv channels, the tv serial also were viewed and accompalished a great success in the Middle East. Even though, tv serial was ended many years ago, it’s still made quite reputation.`
};
export const type = {
    'TR': 'Dizi/Star TV',
    'Eng': 'Tv-Series/Star TV'
};