export const info = {
    'TR': `Türk televizyonlarında yayınlanmış ilk erkek dizisidir.

    Erkekliğin kitabı sloganıyla devam ettiği yıllar boyunca reyting listelerinde her zaman birinciliği korumuştur. Küçük bir mahallede yaşayan insanların gerçek aşkın, samimiyetin ve en önemlisi zorluklara karşı beraber mücadele eden dostların hikayesidir.
    
    5 yıl sürmüş, sektöre bir çok yönetmen, oyuncu ve teknik adam kazandırmış bir dizidir.`,

    'Eng': `Aynalı Tahir is the first man-oriented Turkish series aired on tv.

    With the slogan of the book of masculinity, over the years the series has always been the first in the ratings.
    
    It is the story of the people of true love, sincerity and most importantly, the story of fellows struggling with the challenges who are living in a small neighborhood
    
    The series lasted for five years and made known a lot of director, actor, and technical.`
};
export const type = {
    'TR': 'Dizi/Star TV',
    'Eng': 'Tv-Series/Star TV'
};