import React from 'react';
import { indexes } from '../../utilities/Products/Index'
import Products from '../Utilities/Products/Products.js';
import './Catalog.css'
import Details from '../Utilities/Details/Details';

export default class Catalog extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showDetailsOfProduct: '',
            style: {
                left: 0
            },
            style2: {
                bottom: 0
            }
        }
        this.showDetails = this.showDetails.bind(this);
        this.handleScrollToElement = this.handleScrollToElement.bind(this);
        this.displayAndScroll = this.displayAndScroll.bind(this);
        this.closeDetails = this.closeDetails.bind(this)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollToElement);
    }
    handleScrollToElement(event) {
        if(this.state && this.state.showDetailsOfProduct.name){
            const doc = document.getElementById(`${this.state.showDetailsOfProduct.name}`)
            if(doc && (doc.getBoundingClientRect().bottom < -200 || doc.getBoundingClientRect().y < - 200 || doc.getBoundingClientRect().y > 600)){
                doc.classList.remove('display');
                window.removeEventListener('scroll', this.handleScrollToElement);
                document.getElementById(`${this.state.showDetailsOfProduct.name}z`).style.marginBottom = '0px'
                this.setState({
                    showDetailsOfProduct: ''
                })
            }
        }
    }    
    closeDetails (){
        const doc = document.getElementById(`${this.state.showDetailsOfProduct.name}`)
        doc.classList.remove('display');
        window.removeEventListener('scroll', this.handleScrollToElement);
        document.getElementById(`${this.state.showDetailsOfProduct.name}z`).style.marginBottom = '0px'
    }
    showDetails (product){
        if(this.state && this.state.showDetailsOfProduct.name){
            this.closeDetails();
        }
        this.setState({
            showDetailsOfProduct: product,
         })
        document.getElementById(`${product.name}`).classList.add('display');
        this.displayAndScroll(product.name);
        window.addEventListener('scroll', this.handleScrollToElement);
        document.getElementById(`${product.name}z`).style.marginBottom = '450px'
    }
    displayAndScroll (name) {
        document.getElementById(`${name}`).scrollIntoView({behavior: "smooth", block: "center"})
        return
    }
    render () {
        const { name } = this.state.showDetailsOfProduct;
        const product1 = this.state.showDetailsOfProduct;
        return (
            <div className="container-catalog-page">
                <div className="wrapper-header-catalog">
                </div>
                <div className="wrapper-catalog-page">
                {
                        Object.entries(indexes()).map((keys, value) =>{
                            return(
                                <div className="products-components-wr" key={value} id={`${keys[1].name}z`} >
                                    <Products
                                        showDetails={(e)=> this.showDetails(e)}  
                                        product={keys[1]} component='catalog' >
                                    </Products>
                                    <div className="product-show-details" id={keys[1].name} style={this.state.style} >
                                    { name && 
                                        <Details key={value.toString()} detail={product1} closeDetails={(e)=> this.closeDetails(e)} ></Details>
                                    }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}