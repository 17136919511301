export const info = {
    'TR': `" Tüm hayatımızı tek bir valize sığdırıp yollara düştük annemle… İstanbul'a gidiyoruz. 15 yaşında kadın olmuş benim annem. Benimle birlikte büyümüş nerdeyse… Ama hiç vazgeçmemiş savaşmaktan… Şimdi de benim için savaşıyor ninemle, ağabeylerimle… Ondan daha bir hayatım olsun diye, sonuna kadar okuyayım diye…
    O benim sığınağım, o benim vatanım, o benim her şeyimdir. O benim için hayatından bile vazgeçmeye razı annemdir. 
    Ben Leylan'ın kızıyım ve onun kızı olmaktan gurur duyuyorum…
    Bu hikaye kızını okutabilmek için doğduğu toprakları, Kars’ı bırakıp da İstanbul’a göçen genç bir anne (LEYLAN) ile, okumayı çok isteyen küçük kızının (YONCA) hikayesidir…`,

    'Eng': `"We fit our lives in a single suitcase and hit the road with my mother. We are going to İstanbul. My mother became a woman when she was 15. She has barely grown up with me…But never gives up fighting. And now she is fighting for me against my grandmother and my brother… In order to have a better life, to see my education through to its conclusion.”
    She is my cover, my home, everything. She even dares to give up her existance for me. I am daughter of Leylan and proud of being her daughter.
    This is the story of Leylan who leaves her land Kars in order to send her daughter to school and a little girl Yonca who desires to be educated.`
};
export const type = {
    'TR': 'Dizi/Star TV',
    'Eng': 'Tv-Series/Star TV'
};