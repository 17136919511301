export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMENLER', [
                'Ceyda Demir', ' Cemile Kirmizi Karadas'
            ]
        ],
        [
            'Oyuncular', [
                'Sinem Öztufan',
                'Sermin Hürmeric',
                'Baris Kilic',
                'Saydam Yeniay',
                'Fulya Özcan',
                'Kaan Cakir'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Directors', [
                'Ceyda Demir', ' Cemile Kirmizi Karadas'
            ]
        ],
        [
            'Cast', [
                'Sinem Öztufan',
                'Sermin Hürmeric',
                'Baris Kilic',
                'Saydam Yeniay',
                'Fulya Özcan',
                'Kaan Cakir'
            ]
        ]
    ]
};