import  { detail } from './details'
import  { info, type } from './Info'
export const kaybolanYillar = function(lang: any){
    return {
        name: 'Kaybolan Yıllar',
        photos: require('./Photos.ts').photos,
        infos: info[lang as keyof typeof info],
        details: detail[lang as keyof typeof detail],
        year: '2006',
        type: type[lang as keyof typeof type]
    }
}