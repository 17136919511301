import React from 'react';
import history from '../../../router/history'
import './Language.css'
const w = window;
const choices = [
    'TR', 'Eng'
]
const routes = [
    {
        'TR': `yapimlar`,
        'Eng': `products`
    },
    {
        'TR': `medyada`,
        'Eng': `news`
    },
    {
        'TR': `hakkimizda`,
        'Eng': `about`
    }

]

export default class Language extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            location: choices[0],
            otherLocation: choices[1],
            showOther: false
        };
        this.changeLanguage = this.changeLanguage.bind(this);
        this.findAndReloadRoute = this.findAndReloadRoute.bind(this)
    }
    async componentDidMount() {
        if(w.localStorage.getItem('lang')){
            if(w.localStorage.getItem('lang') === 'TR'){
                this.setState({
                    location: w.localStorage.getItem('lang'),
                    otherLocation: choices[1]
                })
            } else {
                this.setState({
                    location: w.localStorage.getItem('lang'),
                    otherLocation: choices[0]
                })
            }
        } else {
            this.setState({
                location: choices[0]
            });
            w.localStorage.setItem('lang', `${choices[0]}`)
        }
    }
    findAndReloadRoute (path){
        for(let i = 0; i < routes.length; i ++){
            if(path === routes[i][this.state.location]){
                    history.push(`/${routes[i][this.state.otherLocation]}`)
                    w.location.reload()
            }
        }
    }
    changeLanguage (choice){
        this.setState({
            showOther: !this.state.showOther
        })
        if(choice.chang){
            w.localStorage.clear();
            this.setState({
                location: choice.select,
                otherLocation: choice.unSelect
            })
            w.localStorage.setItem('lang', choice.select);
            if(w.location.pathname.split('/')[1]){
                this.findAndReloadRoute(w.location.pathname.split('/')[1])
            } else {
                w.location.reload();
            }
        }
    }
    render () {
        let showOtherOne = this.state.showOther
        return(
            <div className="wrapper-language-component">
                {! showOtherOne &&
                    <div className="show-lang" onClick={(e) => this.changeLanguage({chang: false}, e)} ><div>{this.state.location}</div><div className="arrow-down"></div></div>
                }
                { showOtherOne &&
                <div>
                    <div className="hided-lang" onClick={(e) => this.changeLanguage({chang: true, select: this.state.location, unSelect: this.state.otherLocation }, e)} > <div>{this.state.location}</div><div className="arrow-down"></div></div>
                    <div onClick={(e) => this.changeLanguage({chang: true, select: this.state.otherLocation, unSelect: this.state.location }, e)} className="hided-lang">{this.state.otherLocation}</div>
                </div>
                }
            </div>  
        )
    }
}