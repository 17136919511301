export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Cankat Ergin'
            ]
        ],
        [
            'Senaryo Gurubu', ['Fatih Solmaz']
        ],
        [
            'Oyuncular', [
                'Özlem Conker',
                'Şencan Güleryüz',
                'Tarık Pabuçcuoğlu',
                'İskender Bağcılar',
                'Ayten Uncuoğlu',
                'Nazlı Tosunoğlu',
                'Bedia Öztep'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Cankat Ergin'
            ]
        ],
        [
            'Cast', [
                'Özlem Conker',
                'Şencan Güleryüz',
                'Tarık Pabuçcuoğlu',
                'İskender Bağcılar',
                'Ayten Uncuoğlu',
                'Nazlı Tosunoğlu',
                'Bedia Öztep'
            ]
        ]
    ]
};