import React, { Component } from 'react';
import Routes from './router/router.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    if(error){
      this.setState({ hasError: true });
    }
  }

    render () {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      } else{
        return (
          <div className="main-container">
              {/* before all the routing was in this file... we moved it to router.js for organization */}
              <Routes></Routes>
          </div>
      );
      }
    }
}

export default App;
