export const about = {
    'TR': `Base Productions, Türkiye’nin ilk kadın dizi 
    yapımcılarından ve senaryo yazarlarından Sergin Akyaz 
    tarafından kurulmuştur.Yapımlarıyla kurulduğu günden beri 
    birçok ilke imza atan Base Productions, hayata geçirdiği projeleri 
    Türk dizi sektörüne kazandırdığı başrol oyuncuları ve yönetmenleriyle 
    başarılı projelere imza atmaya devam edecektir.`,
    'Eng': `Base Productions was founded by Sergin Akyaz 
    who is the first woman producer and script writer in 1997. 
    The company’s first series "Aynalı Tahir” has brought a different 
    perspective in series production sector and has been the longest 
    running series in Turkey. After a great number of acclaimed tv serials, 
    "Kaybolan Yıllar” has been the first Turkish tv series sold Middle East 
    and over 50 countries, has got tremendous success on the countries broadcast.

    Since its inception, Base Productions which breaks ground, 
    will continue to implement successful projects with several actors 
    and directors who have been certainly gained to tv production sector 
    by Base Productions.`
}