export const homePage = {
    'TR': `Anasayfa`,
    'Eng': `Home`
}
export const productPage = {
    'TR': `YAPIMLAR`,
    'Eng': `PRODUCTS`
}
export const newsPage = {
    'TR': `MEDYADA`,
    'Eng': `NEWS`
}
export const aboutPage = {
    'TR': `HAKKIMIZDA`,
    'Eng': `ABOUT`
}
export const viewAll = {
    'TR': `Hepsini gör`,
    'Eng': `View All`
}
