export const info:object = {
    'TR': `Aşkın gelişi, aklın gidişidir…
    Birlikte büyüyen, farklı dünyalardan gelen iki kız arkadaşın aralarına giren erkekle birlikte gelişen olayların anlatıldığı günlük bir dizidir.
    Üniversiteyi bitirmek üzere olan Hande ve Ceren çocukluklarından beri iki yakın arkadaştır. Ceren zengin bir ailenin şımarık kızı, Hande ise manikürcü Nermin’in kızı... Mehmet’in hayatlarına girmesiyle iki arkadaşın dostluğu sarsılır. Hande ve Mehmet’in radyo sevgisiyle buluşan sesleri önce dostluğa hemen ardından da aşka dönüşür. Kendi radyolarını kurmak için canla başla çalışan Hande ve Mehmet'in mutluluklarının en büyük engeli ise Ceren’dir. Çünkü Mehmet Ceren'in geçmişte kalan ilk aşkıdır. Ve Ceren tüm hayatını onları ayırmaya adayacaktır...
    Sevgiler nefrete, intikama dönüşür. Kendilerini bekleyen sırlardan, engellerden habersiz birlikte olmaya çalışan Hande ve Mehmet mutlu olabilecek midir?
    Dinle Sevgili, Türk televizyonlarında bir ilki gerçekleştirerek, her bölümünde döneminin en popüler ve önemli müziklerini en güzel aşk sahneleriyle birleştirmiştir... Radyoda çalışan gençlerin hayatları gerçek hayat paralelinde sunulmuş, dizinin kahramanları, gençlerin kendilerine örnek alabilecekleri, yakın hissedebilecekleri, gerçek samimiyeti dostluğu ve aşkı yaşayan karakterler olmuş ve yayınlandığı süre boyunca hep gündemde kalmıştır.`,

    'Eng': `The arrival of love is the departure of mind.
    Dinle Sevgili is a daily tv series tells a story of two girlfriend grown up together, part company because of a man.
    Hande and Ceren who are about to graduate from the college, are two close friends since childhood. Ceren is a spoiled daughter of a wealthy family, on the other hand Hande is daughter of manucurist Nermin. With the enterance of Mehmet into those two close friends’ lives, their friendship is been shaken. At first the voice of Mehmet and Hande meets in the center of radio love, but then this turns into a love. The biggest obstacle for Hande and Mehmet’s happiness who are unsparing in efforts in order to set up a radio, is Ceren. Because Mehmet has been Ceren’s first love and that’s why she devotes herself in seperating them.
    Love should be turned to hatred and revenge. Can Mehmet and Hande be happy together without knowing the pending secrecy and obstacles around them?
    Dinle Sevgili breakes a new ground that in every episode most populer and significant sounds have been combined with the most attractive love scenes. The lives of the young staff in the radio have been submitted in the line of real life.The young viewers have followed the characters’s lead and these characters in the series have been always the ones who share the love, sincerty. That’s why the audience always feel themselves close to the characters and the series is always been the order of the day.`
};
export const type = {
    'TR': 'Dizi/FoxTV',
    'Eng': 'Tv-Series/FoxTV'
};