export const detail =  {
    'TR':[
        [
            'Ülke', ['ABD']
        ],
        [
            'Dil', ['İngilizce']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Yöneten/Yazan', ['Paul Leyden']
        ],
        [
            'Yürütücü yapımcı',['Anne Clements']
        ],
        [
            'Süre', ['90dk']
        ],
        [
            'Kamera', ['Arri Alexa Cooke S4 Lenses']
        ],
        [
            'Müzik', ['Thomas Morse']
        ],
        [
            'Görüntü Yönetmeni', ['Joseph White']
        ],
        [
            'Oyuncular', [
                'Katie Walder',
                'Matt Passmore', 'Nathan Keyes',
                'Laura Gordon'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['USA']
        ],
        [
            'Language', ['English']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Director/writer', ['Paul Leyden']
        ],
        [
            'Producer', ['Anne Clements']
        ],
        [
            'Runtime', ['90mins']
        ],
        [
            'Camera', ['Arri Alexa Cooke S4 Lenses']
        ],
        [
            'Original Music by', ['Thomas Morse']
        ],
        [
            'Cinematography', ['Joseph White']
        ],
        [
            'Cast', [
                'Katie Walder',
                'Matt Passmore', 'Nathan Keyes',
                'Laura Gordon'
            ]
        ]

    ]
};