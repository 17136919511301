import React from 'react';
import { indexes } from '../../utilities/About/Index'
import './About.css'
export default class About extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render () {
        return (
            <div className="container-about">
                <div className="images-about">
                    {
                        indexes().photos.map((keys) =>{
                            return(
                                <img key={keys.toString()} className="image-about" src={keys} alt=""></img>
                            );
                        })
                    }
                </div>
                <div className="contant-box-about">
                    <h3>{indexes().header}</h3>
                    <p>{indexes().text}</p>
                </div>
            </div>
        );
    }
}