import React from 'react';
import './Iframe.css'
const w = window;

export default class Iframe extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        
        };
        this.redirectExtLink = this.redirectExtLink.bind(this);
    }
    redirectExtLink (){
        w.open(this.props.url);
    }
    render () {
        return(
            <div className="wrapper-iframe-component">
                <img onClick={(e)=> this.redirectExtLink(e)} title="cmbt" id="cbmt" className="cbnmt-ifrm" src={this.props.photo} alt=""></img>
            </div>  
        )
    }
}