export const info = {
    'TR': `Nazım ile Bahar, dört çocuk sahibi,
     birbirlerini seven bir çifttir. Karakterleri
      taban tabana zıt olunca ev hayatı da epey renkli
    ve hareketlidir. Bazılarına göre, çiftin aynı evde
     yaşamaları bile mucizedir. Ancak onlar farklılıklarına ve fikir 
     ayrılıklarına rağmen aile hayatlarının ve çekirdek ailelerini bir arada tutmaya çalışırlar.`,

    'Eng': `Nazım and Bahar are married and 
    lovely couple with four kids. Because of their 
    differences, the life in the house is so joyful 
    and eventful. It’s miraculous for those two living 
    together. Despite their differences, they keep their 
    family together and maintain their happiness.`
};
export const type = {
    'TR': 'Dizi/Star TV',
    'Eng': 'Tv-Series/StarTV'
};