export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Hale Demiray'
            ]
        ],
        [
            'Oyuncular', [
                'Aylin Poyraz',
                'Erman Burmalı',
                'Aykut Sözeri',
                'Ayşen İnci'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Hale Demiray'
            ]
        ],
        [
            'Cast', [
                'Aylin Poyraz',
                'Erman Burmalı',
                'Aykut Sözeri',
                'Ayşen İnci'
            ]
        ]
    ]
};