import React from 'react';
import { Link } from 'react-router-dom';
import logoHere from '../../assets/images/logo/logoHere.png';
import facebookWhite from '../../assets/images/socialMedia/facebookWhite.png';
import facebookBlue from '../../assets/images/socialMedia/facebookBlue.png';
import twitterWhite from '../../assets/images/socialMedia/twitterWhite.png';
import twitterBlue from '../../assets/images/socialMedia/twitterBlue.png';
import instagramWhite from '../../assets/images/socialMedia/instagramWhite.png';
import instagramOrange from '../../assets/images/socialMedia/instagramOrange.png';
import { indexes } from '../../utilities/Routes/Index';
import './Navbar.css';
import Language from '../Utilities/Language/Language';
const w = window;
let changClass = true;

const imageHash = {
    'f': [facebookWhite, facebookBlue, 'https://www.facebook.com/BASE-PRODUCTIONS-271966686178113/'],
    'i': [instagramWhite, instagramOrange, ''],
    't': [twitterWhite, twitterBlue, '']
};
export default class Navbar extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            'f': imageHash.f[0],
            't': imageHash.t[0],
            'i': imageHash.i[0]
        };
        this.changeOnHover = this.changeOnHover.bind(this);
        this.changeOnHover2 = this.changeOnHover2.bind(this);
        this.openExternalLink = this.openExternalLink.bind(this);
    }
    componentDidMount() {
        if(w.localStorage.getItem('lang') === 'Eng'){
            changClass = false;
        } else {
            changClass = true;
        }
    }
    changeOnHover (direct) {
        this.setState({
            [direct]: imageHash[direct][1]
        });
    }
    changeOnHover2 (direct) {
        this.setState({
            [direct]: imageHash[direct][0]
        });
    }
    openExternalLink (direct) {
        w.open(imageHash[direct][2]);
    }
    render (){
        if(w.localStorage.getItem('lang') === 'Eng'){
            changClass = false;
        } else {
            changClass = true;
        }
        return (
            <div className="navigation">
                <div className="logo-wrapper"><Link to={{pathname: '/'}} className="link" ><img className="logo" src={logoHere} alt="logo"/></Link></div>
                <div className="text-link-wrap first"><Link to={{pathname: '/'}} className="link"><span className="text-link">{indexes().homePage.toUpperCase()}</span></Link></div>
                <div className={'text-link-wrap '+ (changClass? 'second': 'second2')}><Link to={{pathname: `/${indexes().productPage.toLowerCase()}`}} className="link"><span className="text-link">{indexes().productPage}</span></Link></div>
                <div className="text-link-wrap third"><Link to={{pathname: `/${indexes().newsPage.toLowerCase()}`}} className="link"><span className="text-link">{indexes().newsPage}</span> </Link></div>
                <div className={'text-link-wrap ' + (changClass? 'second': 'second2')}><Link to={{pathname: `/${indexes().aboutPage.toLowerCase()}`}} className="link"> <span className="text-link">{indexes().aboutPage}</span></Link></div>
                <div className="external-links">
                    <img className="facebook" onMouseEnter={(e) => this.changeOnHover('f', e)}
                        onClick={(e) => this.openExternalLink('f', e)}
                        onMouseLeave={(e) => this.changeOnHover2('f', e)} src={this.state.f} alt="facebook"></img>
                    <img className="twitter" onMouseEnter={(e) => this.changeOnHover('t', e)}
                        onClick={(e) => this.openExternalLink('t', e)}
                        onMouseLeave={(e) => this.changeOnHover2('t', e)} src={this.state.t} alt="twitter"></img>
                    <img className="instagram" onMouseEnter={(e) => this.changeOnHover('i', e)}
                        onClick={(e) => this.openExternalLink('i', e)}
                        onMouseLeave={(e) => this.changeOnHover2('i', e)} src={this.state.i} alt="instagram"></img>
                </div>
                <div className="language-container">
                    <Language></Language>
                </div>
            </div>
        );
    }
}