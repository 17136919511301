import { homePage, newsPage, aboutPage, productPage, viewAll } from './Routes';

let lang = 'TR';
export const indexes = function(){
    if(window.localStorage.getItem('lang')){
        lang = `${window.localStorage.getItem('lang')}`;
    }
    return {
        homePage: homePage[lang as keyof typeof homePage],
        newsPage: newsPage[lang as keyof typeof newsPage],
        aboutPage: aboutPage[lang as keyof typeof aboutPage],
        productPage: productPage[lang as keyof typeof productPage],
        viewAll: viewAll[lang as keyof typeof viewAll]
    };
};