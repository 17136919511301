import React from 'react';
import './Details.css'

export default class Details extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
        this.changeImage = this.changeImage.bind(this)
    }
    changeImage (index){
        this.setState({
            counter: index
        })
    }
    render () {
        console.log(this.props)
        let nameDetail = this.props.detail.name;
        let coUnter = this.state.counter;
        let imageFirst;
        if(nameDetail === 'Aynalı Tahir' && coUnter === 0){
            imageFirst = <img id="firstphoto" className="img-detail2" src={this.props.detail.photos[this.state.counter]} alt=""></img>
        } else {
            imageFirst = <img id="firstphoto" className="img-detail" src={this.props.detail.photos[this.state.counter]} alt=""></img>
        }
        return(
            <div>
                <div className="close-wrapper-detail"> <span onClick={(e)=> this.props.closeDetails(e)} className="close-span">X</span></div>
                <div className="details-container">
                    <div className="left-image-side">
                        <div className="img-detail-wrapper" id="img-detail-wrapper">
                            {imageFirst}
                        </div>
                        { this.props.detail.photos.length > 1 &&
                            <div className="image-container-detail" id="image-container-detail">
                                {
                                    this.props.detail.photos.map((source, index)  =>{
                                        return(
                                            <img key={index.toString()} src={source} className="subImages-detailpage" alt="" onClick={(e)=>this.changeImage(index, e)}></img>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className="middle-cast-wrapper">
                        {
                            this.props.detail.details.map((info, index)  =>{
                                return(
                                        <div key={index.toString()} className="wrapper-props-details">
                                            <div className="wrapper-props-details-info-zero">{info[0]}:</div>
                                            <div className="wrapper-props-details-info-one">
                                                {
                                                    info[1].map((infoName, index2)=>{
                                                        return(
                                                            <div className="wrapper-props-details-info-one-name" key={index2.toString()}>{infoName}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                            })
                        }
                    </div>
                    <div className="last-detail-infos-wrapper">
                        <h3 className="last-detail-infos-wrapper-header">{this.props.detail.name} - {this.props.detail.type}</h3>
                        <p>{this.props.detail.infos}</p>
                    </div>
                </div> 
            </div>
        )
    }
}