import  { cmtb }  from './ComeBackToMe/index';
import { arafZamani } from './ArafZamani/index';
import { dinleSevgili } from './DinleSevgili/index';
import { aileReisi } from './AileReisi/index';
import { damatBegendi } from './DamatBegendi';
import { goncaKaranfil } from './GoncaKaranfil';
import { leylan } from './Leylan';
import { duvar } from './Duvar';
import { kaybolanYillar } from './KaybolanYillar';
import { atesliTopraklar } from './AtesliTopraklar';
import { metroPalas } from './MetroPalas';
import { melek } from './Melek';
import { deryaDeniz } from './DeryaDeniz';
import { aylin } from './Aylin';
import { zilyoner } from './Zilyoner';
import { guzelGunler } from './GuzelGunler';
import { aynaliTahir } from './AynaliTahir';
let lang = 'TR';
if(window.localStorage.getItem('lang')){
    lang = `${window.localStorage.getItem('lang')}`;
}
export const indexes = function(){
    return {
        'Come Back To Me': cmtb(lang),
        'Araf Zamanı': arafZamani(lang),
        'Dinle Sevgili': dinleSevgili(lang),
        'Aile Reisi': aileReisi(lang),
        'Damat Beğendi': damatBegendi(lang),
        'Gonca Karanfil': goncaKaranfil(lang),
        'Leylan': leylan(lang),
        'Duvar': duvar(lang),
        'Kaybolan Yıllar': kaybolanYillar(lang),
        'Ateşli Topraklar': atesliTopraklar(lang),
        'Metro Palas': metroPalas(lang),
        'Melek': melek(lang),
        'Derya Deniz': deryaDeniz(lang),
        'Aylin': aylin(lang),
        'Zilyoner': zilyoner(lang),
        'Güzel Günler': guzelGunler(lang),
        'Aynalı Tahir': aynaliTahir(lang)
    };
};
export const headerCatalog = function() {
    const headers = {
        'TR': 'YAPIMLARIMIZ',
        'Eng': 'Productions'
    }
    return headers[lang as keyof typeof headers]
}