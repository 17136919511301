export const info = {
    'TR': `Evlilik iki insanın değil ailelerin bir araya gelmesidir.
    Derya Trabzonlu, Deniz Urfalı geleneksel ailelerin 
    İstanbul’da tanışıp aşık olmuş ve evlenmiş 
    çocuklarıdır. Aşkları ne kadar büyük olsa da, 
    ailelerinin farklılıklarını ve geleneksel 
    kurallarını yenmek zorundadır. Ancak bu o kadar 
    kolay olmayacaktır, Derya & Deniz’in her bölüm 
    yaptıkları planlar sonunda başarısız olsa da onlar 
    aşkları için tekrar denemekten vazgeçmezler.`,

    'Eng': `Marriage is not only two persons’ coming together, but also their families.
    Derya is the daughter of traditional Trabzon family and Deniz is son of traditional Urfa family, falls in love in Istanbul and marry. No matter how big their love, the have to overcome the differences and traditional rules of their families.But, this is not easy. Although their plans fail at each episode, they never give up trying for their love.`
};
export const type = {
    'TR': 'Dizi/TGRT',
    'Eng': 'Tv-Series/TGRT'
};