export const info = {
    'TR': `Görevi uğruna aşkından, hayatından, insanlığından vazgeçmiş bir polis… Aşkı için savaşmaktan usanmayan, intikam duygusunun ele geçirdiği bir kadın… Sakladığı sırrın ağırlığıyla ezilmiş bir anne… Birbirlerine düşman bir baba-oğul… 
    ARAF ZAMANI, bir şehit çocuğu olan polis Ali’nin hayatını adadığı gizli görevi uğruna tüm sevdiklerini ve gerçek kimliğini kaybetmesi ama bu acımasız yolculukta kendi geçmişinin sırlarına ulaşmasının hikayesidir. 
    ARAF ZAMANI, karanlıkla aydınlığın, iyilikle kötülüğün, geçmiş ile geleceğin tam ortasına hapsolmuş insanları; Araf’ta kalmış hayatları anlatır.`,
    'Eng': `A police officer who gives up his life, his love and his humanity in order to his duty...
    A woman who never gives up her love and loses her way in her rage...
    A mother who is crushed under the secret she’s been bearing for years...
    A father and a son who are enemies to each other ... 
    ARAF ZAMANI is the story of a martry’s son who is dedicated his life to the secret service. He finds out the secrets from his past while he loses his family and his identity.
    ARAF ZAMANI tells the people who are locked in between the darkness and the light, the evil and the goodness, the past and the future, and tells the lives stuck in Purgatory.`
};
export const type = {
    'TR': 'Dizi/FoxTV',
    'Eng': 'Tv-Series/FoxTV'
};