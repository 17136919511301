export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMENLER', [
                'Zeynep Günay Tan', 'Gülçin Gülbahçe'
            ]
        ],
        [
            'Oyuncular', [
                'Saruhan Hünel',
                'Burak Hakkı',
                'Yeşim Büber',
                'Fikret Hakan',
                'Mahir Günşiray',
                'Semra Dinçer',
                'Muhammet Cangören',
                'Sema Çeyrekbaşı',
                'Sevtap Parman',
                'Taner Barlas'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Directors', [
                'Zeynep Günay Tan', 'Gülçin Gülbahçe'
            ]
        ],
        [
            'Cast', [
                'Saruhan Hünel',
                'Burak Hakkı',
                'Yeşim Büber',
                'Fikret Hakan',
                'Mahir Günşiray',
                'Semra Dinçer',
                'Muhammet Cangören',
                'Sema Çeyrekbaşı',
                'Sevtap Parman',
                'Taner Barlas'
            ]
        ]
    ]
};