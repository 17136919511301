export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Gülizar Vatanlar'
            ]
        ],
        [
            'Oyuncular', [
                'Alişan',
                'Aslihan Gürbüz',
                'Devrim Saltoglu',
                'Esref Kolcak',
                'Efe Deprem',
                'Serhat Nalbantoglu',
                'Sarp Akkaya'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Gülizar Vatanlar'
            ]
        ],
        [
            'Cast', [
                'Alişan',
                'Aslihan Gürbüz',
                'Devrim Saltoglu',
                'Esref Kolcak',
                'Efe Deprem',
                'Serhat Nalbantoglu',
                'Sarp Akkaya'
            ]
        ]
    ]
};