export const info = {
    'TR': `Türkiye’nin ilk yerli gündüz soap-operasıdır. Haftaici hergün kadın izleyiciyi sımsıcak hikayesi ile ekranlara kitleyen Aylin, küçük yaşlarda birbirlerinden koparılan iki kardeşin yıllar sonra tesadüfen karşılaşmasını anlatır.

    Hasta annesinin bakımını üstlenen çiçekçi kız Aylin ile babasının zoruyla sevmediği bir kızla evlendirilmek istenen zengin ve yakışıklı Ferhat’ın imkansız aşkının hikayesidir`,

    'Eng': `Aylin is the first domestic day-time soap-opera in Turkey.

    Aylin,with its warm story binds the audience to the screen weekdays, tells the story of two sibling who were seperated from each other but met quiet by chance years later. It is the story of impossible love of florist Aylin who takes care of her sick mother, and rich, handsome Ferhat who is forced to marry with a unliked girl by his parent.`
};
export const type = {
    'TR': 'Dizi/Star TV',
    'Eng': 'Tv-Series/Star TV'
};