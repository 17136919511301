export const info = {
    'TR': `Gecekondularla malikaneleri, zenginlerle yoksulları, kentlilerle göçenleri tek bir duvar ayırır... Yıkmaya kimsenin gücü yetmez çünkü bu kaderin ördüğü bir duvardır... 
    Ve kırılan her genç kalp için , ‘Duvar’a bir tuğla daha eklenir… Duvar; eğlenen gençleri anlatan dizilerin tam aksine derdi olan gençleri anlatır.
    Tepecountry; malikanelerin olduğu etrafı korunaklı duvarlarla çevrili, güçlü güvenlik sistemleriyle korunan bir sitedir. Kurutepe ise onun tam karşısında yıllardır sessizce oturan bir gecekondu mahallesi… Ve onları ayıran bir duvar yükselir ortada… İki grup da birbirlerini hiç tanımadan, dokunmadan sakin sakin yaşarlarken; yılbaşı gecesi zengin armatörün oğlu Taylan kırmızı Ferrari’siyle Kurutepe gençlerinden birine çarpar. Ve ölü genci bırakıp kaçar gider. Taylan olayı kimsenin görmediğini sanır ama yanılmıştır. Sevgilisi İklim ve Kurutepeli Ege her şeyi görmüştür… 
    Ege, Ferrari'nin arkasından koşup yetişememiş, Kurutepeli'ler ayaklanmış, mahkemelerle uğraşmış ama hiçbir şey elde edememişlerdir. Ölen öldüğüyle, zengin olan ise Ferrari'siyle kalmıştır… Ama Ege ve arkadaşları sessiz kalmayacaklardır…
    Artık onları ayıran duvarlar yıkılmış, intikam saati gelmiştir. 
    Aşkları, tutkuları, bambaşka hayatları ve ördükleri duvarlarıyla genç bir dünyanın kapıları ardına kadar açılacaktır.
    Duvar'ın bir farkı da genç oyuncularıdır… Hiç birisi oyuncu değildir, sokaktan gelmiş ve hepsi enerjileri için seçilmiş, eğitilmiştir…`,

    'Eng': `Only one Wall comes between the wealthy and the poverty, emigrants and citizens, mansions and slum houses...
    No one can have the power to destroy it, because it’s built by destiny...
    A new brick is placed into the Wall for the broken heart. Duvar tells us troubled youth, on the contrary the tv serials which shows ignorant and happy youth.
    Tepecountry is a site which has high walls around, guarding from the outsiders. On the other hand Kurutepe is a slum neighbourhood right across Tepecountry. There is a Wall standing between them.
    Two group of these people have lived side by side without knowing and touching each other for many years, since that new years eve, Taylan whose father is a rich ship owner, hit a young from Kurutepe with his red Ferrari and run without looking back.
    Taylan thinks noone sees the accident, yet his girlfriend and Ege from Kurutepe see everthing. Ege run after the Ferrari but couldn’t catch it. The people from Kurutepe run riot and complain in a court of law, but nothing’s changed. Dead young kid remains dead, the rich boy continues with his Ferrari. But Ege and his friends won’t be silence to this.
    The Wall seperating two side no longer keeps them apart, it’s time to take revenge.
    The doors between the wealthy and the poverty, are wide open now with love, hate, passion and new way of living.
    Duvar’s difference from the other tv serials is the young players. None of them are professional actors, and all of them are recruited from the streets for their heart and are trained.`
};
export const type = {
    'TR': 'Dizi/ATV',
    'Eng': 'Tv-Series/ATV'
};