export const info = {
    'TR': `Türkiye’de yetişkinler için yapılmış ilk fantastik dizidir.

    Melek, Anadolu’daki büyü ve doğaüstü güçlere inanç geleneğinin dayandığı bir coğrafyada, güzeller güzeli bir kızın aşkı buluşunu ve onun için mücadelesini anlatır. Babaanne torununun doğumunda gördüğü peri kızını bir işaret saymış ve Melek'in özel bir çocuk olduğuna inanmıştır. Melek ise sahip olduğu doğaüstü yeteneklerin farkında olmadan büyümüş, doğumda gözüken peri kızını çoğu zaman babaannesinin anlattığı bir masal kahramanı gibi dinlemiştir. Ne de olsa masallarla, şahmeran ustası babasının resmettiği şahmeran efsanesini dinleyerek büyümüştür. Bir masal ülkesindeki gibi mutlu bir hayat süren Melek'in kaderi, kasabaya film çekimi için gelen Ferhat'la bir anda değişiverir. Aşkını bir türlü itiraf edemeyen Melek, önce Ferhat'ın İstanbul'a çekip gitmesiyle sarsılır. Ardından babasının onu istemediği bir evliliğe zorlamasıyla yıkılır. Tam tüm umutlarını kaybettiği anda beklenen mucize gerçekleşir. Rüyalarında gördüğü babaannesinin anlatıp durduğu, onu sonsuza dek koruyacak peri kızının karşısındadır. O andan sonra Melek'in hayatı asla eskisi gibi olmayacaktır.`,

    'Eng': `t is the first fantastic tv serial for grown-ups.
    Melek tells us a story of a beautiful girl who is searching and figthing for her love. The story takes place in a small Anatolian town where has strong belief of witchcraft and supernatural beings.
    Melek’s grandmother thought of seeing a nymph during Melek’s birth and she assumed this as a sign which meant that Melek has been a special girl. Melek was brought up without knowing her special gift and most of the time, she listened to the nymph as a fairy tale told by her grandmother. After all, she grew up with fairy tales and the myth of Shahmaran which was drawn by her father. Melek who is as happy as living in a fairy tale, has a destiny about to change, as soon as she meet Ferhat who comes to the town for a movie shot. Melek can not admit her feelings for him, till Ferhat’s leaving. She is hit hard by then. There is something else to make her unhappy. Her father forces her to marry a man she doesn’t have any feelings for. The moment she loses all hope for life, all of a sudden miracle happens. The nymph which’s been told and appeared in her dreams since her childhood to saying protecting her forever, is in front of her. After that moment Melek’s life will never be the same again.`
};
export const type = {
    'TR': 'Dizi/Show TV',
    'Eng': 'Tv-Series/Show TV'
};