export const info = {
    'TR': `Türkiye’de ilk defa ve yüksek sesle, şehirli modern kadınların hikayesinin anlatıldığı dizidir.
    30'lu yaşlardaki kadınların sıcacık öyküsü...
    METRO PALAS’taki kadınlar, hayal ettikleri kariyerlerine kavuşmuş, iyi gelir düzeyine sahip ama kötü giden ilişkiler yaşayan ve mutluluk kavramını başka yerlerde arayan insanlardır.
    Tarz giyinirler, trendleri yakından takip ederler. Hemen hepsi üniversite mezunudur, başlarına gelenler dram değil, içinde komedi barındıran olaylardır. Dünyanın neresinde olursa olsun bir metropolde yaşayan herkesin yaşama dair problemlerini anlatır`,

    'Eng': `In Turkey, for the first time this tv series tells loudly the story of modern women living in cities,
    The sincere story of the women in 30’s...
    The women in METROPALAS are the people who have successful careers, make good money for living and also have bad relationships and because of that they are seeking happiness elsewhere.
    They have good sense of fashion, wearing stylish brands and catching up all the fashion trends. All of them graduated from universities.
    What happens to them is not only drama but also humorous stories. This serial tells the problems of all living in metropole no matter where is.`
};
export const type = {
    'TR': 'Dizi/Show Tv',
    'Eng': 'Tv-Series/Show Tv'
};