export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Murat Saraçoğlu'
            ]
        ],
        [
            'Oyuncular', [
                'Seda Güven',
                'Uğur Çavuşoğlu',
                'Arzu Gamze Kılınç',
                'Ümit Tombak',
                'Muhammet Uzuner',
                'Bülent Polat'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Murat Saraçoğlu'
            ]
        ],
        [
            'Cast', [
                'Seda Güven',
                'Uğur Çavuşoğlu',
                'Arzu Gamze Kılınç',
                'Ümit Tombak',
                'Muhammet Uzuner',
                'Bülent Polat'
            ]
        ]
    ]
};