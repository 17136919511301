import React from 'react';
import { indexes, headerCatalog } from '../../../utilities/Products/Index.ts';
import './HomeMidContainer.css';
import Products from '../../Utilities/Products/Products.js';
import { Route } from 'react-router-dom'
import { indexes as indexes2 } from '../../../utilities/Routes/Index'
export default class HomeMidContainer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            indexArr: []
        };
    }
    render () {
        return (
            <div className="wrapper-mid"> 
                <p className="header-me">{headerCatalog()}</p>
                <div className="mid-container">
                    {
                        Object.entries(indexes()).slice(0,3).map((keys, value) =>{
                            return(
                                <Products key={value} product={keys[1]}></Products>
                            );
                        })
                    }
                    <div className="navigation-mid">
                    <Route render={({ history}) => (
                            <p onClick={() => history.push(`/${indexes2().productPage.toLowerCase()}`)} className="link-me">{indexes2().viewAll}...</p>
                        )} />
                    </div>
                </div>
            </div>
        );
    }
}