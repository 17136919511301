export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Yazan', ['Sergin Akyaz']
        ],
        [
            'Senaryo Gurubu', ['Güliz Kucur', 'Can Sinan']
        ],
        [
            'YÖNETMENLER', [
                'Cankat Ergin',
                'Fulya Yavuzoğlu',
                'Andaç Haznedaroğlu'
            ]
        ],
        [
            'Oyuncular', [
                'Saruhan Hünel',
                'Selin Ortaçlı', 'Burak Sergen',
                'Sermin Hürmeriç',
                'Hakan Ural',
                'Basak Sayan',
                'Serkan Kuru',
                'Lale Cangal',
                'Duygu Sen',
                'Funda Eskioğlu'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer group', ['Güliz Kucur', 'Can Sinan']
        ],
        [
            'Directors', [
                'Cankat Ergin',
                'Fulya Yavuzoğlu',
                'Andaç Haznedaroğlu'
            ]
        ],
        [
            'Cast', [
                'Saruhan Hünel',
                'Selin Ortaçlı', 'Burak Sergen',
                'Sermin Hürmeriç',
                'Hakan Ural',
                'Basak Sayan',
                'Serkan Kuru',
                'Lale Cangal',
                'Duygu Sen',
                'Funda Eskioğlu'
            ]
        ]
    ]
};