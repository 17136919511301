export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMEN', [
                'Volkan Kocatürk'
            ]
        ],
        [
            'Oyuncular', [
                'Taylan Güner',
                'Sinem Öztürk',
                'Bülent Çetinaslan',
                'Arzu Gamze Kılınç',
                'Suavi Eren',
                'Turgay Tanülkü'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Director', [
                'Volkan Kocatürk'
            ]
        ],
        [
            'Cast', [
                'Taylan Güner',
                'Sinem Öztürk',
                'Bülent Çetinaslan',
                'Arzu Gamze Kılınç',
                'Suavi Eren',
                'Turgay Tanülkü'
            ]
        ]
    ]
};