export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo', ['Sergin Akyaz']
        ],
        [
            'YÖNETMENLER', [
                'Cankat Ergin', 'Hilal Saral'
            ]
        ],
        [
            'Oyuncular', [
                'Alişan',
                'Saruhan Hünel',
                'Erkan Petekkaya',
                'Yesim Büber',
                'Özlem Conker',
                'Serif Sezer',
                'Didem Ugurlu'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer',  ['Sergin Akyaz']
        ],
        [
            'Directors', [
                'Cankat Ergin', 'Hilal Saral'
            ]
        ],
        [
            'Cast',  [
                'Alişan',
                'Saruhan Hünel',
                'Erkan Petekkaya',
                'Yesim Büber',
                'Özlem Conker',
                'Serif Sezer',
                'Didem Ugurlu'
            ]
        ]
    ]
};