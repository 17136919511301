import React from 'react';
import './Products.css';

export default class Products extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        
        };
    }
    render () {
        const Catalog = this.props.component;
        if(Catalog) {
            return (
                <div className="product-container-catalog">
                    <div className="poster-wrapper">
                        <img onClick={(e)=> this.props.showDetails(this.props.product, e)} className="poster-two" src={this.props.product.photos[0]} alt={this.props.product.name}></img>
                    </div>
                    <div className="info-box">
                        <p>{this.props.product.name}-{this.props.product.year}</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="product-container">
                    <div className="poster-wrapper">
                        <img  className="poster" src={this.props.product.photos[0]} alt={this.props.product.name}></img>
                    </div>
                    <div className="info-box">
                        <p>{this.props.product.name}-{this.props.product.year}</p>
                    </div>
                </div>
            );
        }
    }
}