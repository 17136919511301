import React from 'react';
import { videoLinks } from '../../utilities/videoLinks/homePage.ts';
import HomeMidConatiner from './HomeMidContainer/HomeMidContainer.js';
import './Home.css';

export default class Home extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            sourceOfVideo: videoLinks.trailers[0][1],
        };
        // this.playVideo = this.playVideo.bind(this);
    }
    componentDidMount() {
        // setTimeout(()=>{
        //     this.playVideo();
        // }, 500);
    }
    // playVideo(){
    //     const video = document.getElementById('video');
    //     setTimeout(()=>{
    //         video.play();
    //     }, 100);
    // }
    render () {
        return (
            <div>
                <div className="home-container" onLoad={this.playVideo}>
                    <div className="title-of-video">
                        {videoLinks.trailers[0][0]}
                    </div>
                    {
                      videoLinks.trailers[0][1] &&
                      <video  autoPlay controls muted loop playsInline id="video" className="video-frame" width="320" height="240"  poster="https://res.cloudinary.com/dmdcrs852/image/upload/v1553197608/ComeBackToMe/cbtm.jpg" >
                          <source src={this.state.sourceOfVideo} type="video/mp4"/>
                          <source  src={this.state.sourceOfVideo} type="video/ogg"/>
            Your browser does not support the video tag.
                      </video>
                    }
                    <div className="cover-video">
                    </div>
                </div>
                <HomeMidConatiner></HomeMidConatiner>
            </div> 
        );
    }
}