import React from 'react';
import {  Router, Route, Switch } from 'react-router-dom';
import history from './history'
import Home from '../components/Home/Home.js';
import About from '../components/About/About.js';
import NotFound from '../components/NotFound/NotFound.js';
import Navbar from '../components/Navbar/Navbar';
import Catalog from '../components/Catalog/Catalog.js';
import News from '../components/News/News.js';
import Footer from '../components/Footer/Footer.js';
import { indexes } from '../utilities/Routes/Index';
import ScrollToTop from '../ScrollToTop'

export default class Routes extends React.Component{
    render (){
        return (
            <div>
                <Router   history={history} >
                    <Navbar></Navbar>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <Route path={`/${indexes().newsPage.toLowerCase()}`} component = {News}/>
                        <Route path = {`/${indexes().aboutPage.toLowerCase()}`} component = {About}/>
                        <Route path = {`/${indexes().productPage.toLowerCase()}`} component = {Catalog}/>
                        <Route component={NotFound}/>
                    </Switch>
                    <Footer></Footer>
                </Router>
            </div>
        );
    }
}
