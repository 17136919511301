export const info = {
    'TR': `Sarah ve Josh Mclaren,
    Las Vegas'ın banliyösünde yaşayan genç,
    evli bir çifttir. Trafik kazasından sonra, 
    Sarah her dakikasını özellikle de aile yaşantısını dolu dolu yaşamaya başlar.
    Giderek artan hafıza kaybı, yaşadıklarını hatırlamaması ve kocası kısırken hamile kaldığını fark etmesi,
    Sarah'ın evliliğini ve dünyasını yıkar. Gerçekleri gün yüzüne çıkarmak için 
    gizli kamera yerleştirir ve yaşadığı gerçekle donakalır. Yaşamdaki tek kesin şey, ölüm müdür?`,
    'Eng': `Sarah & Josh McLaren are a young married couple who live in suburban Las Vegas. Following 
    a recent car accident, Sarah is determined to live every moment to the full which includes starting a family. 
    When she begins to suffer a series of disturbing memory blackouts that increase in intensity and discovers 
    that she is pregnant (despite the reveal that her husband is sterile) her marriage and her world falls apart. 
    In a final desperate act, she places a secret camera in her house, only to watch in petrified horror the reality of her situation. 
    The one certain thing in life is death… or is it?`
};
export const type = {
    'TR': 'Film',
    'Eng': 'Movie'
};