export const photos = [
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873689/News/IMG_8CEDC4-54132B-2AC0B3-3FDB44-FAB9E7-B371A9.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873704/News/IMG_BAADBF-2FEB26-253584-E66DCA-AFEA02-F5FD7A.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873698/News/IMG_BB010A-D0A39B-8F7D72-7F8D10-9A70F4-378127.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873697/News/IMG_D005C4-3D0258-6DE597-B87288-791B23-11A4E3.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873694/News/IMG_045B0D-A596AB-D5904D-CB920C-A45016-BF7A0F.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873709/News/IMG_FB1362-ED312C-54DE14-69D70C-09D44C-464126.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873712/News/IMG_12C1A4-461FD9-28A13A-7DEF20-4F1F96-A50B8A.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873709/News/IMG_84FBA8-697321-D79939-CFFAF8-2EAE22-2C4302.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873709/News/IMG_DD00F8-0EC64C-006E40-420979-916BE4-B604EB.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873707/News/IMG_F8FDFC-98152C-2292AF-4DE19B-DD0A89-ECE864.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873694/News/IMG_5E3E3F-26E1FD-D1EA8C-F968A8-EF225A-53C667.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873689/News/IMG_6BC99F-7063C8-F9F480-94B586-738BAA-C676AC.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873688/News/IMG_3B5F24-F635D0-348400-90B9EC-965AD7-AE51F7.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873711/News/IMG_FA76C0-D71AE3-CBB8FB-722F17-79F93F-689DF0.jpg',
    'https://res.cloudinary.com/dmdcrs852/image/upload/v1553873702/News/IMG_E5BC28-5A4C38-6A4F20-FE9D85-739BBD-A1BC0B.jpg'
];