export const info = {
    'TR': `‘HAYALİ OLMAYANIN HAYATI YOKTUR’
    GoncaKaranfil hayattan büyük beklentileri olan, hayalleriyle yaşayan, yılmadan var olmaya çalışan ancak hep hüsrana uğrayan insanların hikayesidir.
    Gonca ve Karanfil Kemal'in tutkulu aşkı çevresinde ilerleyen Goncakaranfil, mutlulukları, içimizi burkan hüzünleri, herkesin başına gelebilecek dramları, kendine çeken büyüsüyle bir mahalle masalıdır.`,

    'Eng': `"A man without dream, does not have a life”
    GoncaKaranfil is the story of the people who have great expectations, dreams, and who are struggling for survival but just always frustrated..
    GoncaKaranfil is a fairy tale, tells the love of Gonca and Karanfil Kemal and their happinesses, sorrows and common dramas that all the audiences share and find themselves in it.`
};
export const type = {
    'TR': 'Dizi/ATV',
    'Eng': 'Tv-Series/ATV'
};