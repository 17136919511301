import { about } from './About';
import { header } from './Header'
let lang = 'TR';
export const indexes = function(){
    if(window.localStorage.getItem('lang')){
        lang = `${window.localStorage.getItem('lang')}`;
    }
    return {
        photos: require('./Images.ts').photos,
        text: about[lang as keyof typeof about],
        header: header[lang as keyof typeof header]
    };
};