export const detail =  {
    'TR':[
        [
            'Yapımcı', ['Sergin Akyaz']
        ],
        [
            'Senaryo Gurubu', ['Yılmaz Sahin', 'Sema Ergenekon', 'Eylem Canpolat',
                'Gökhan Horzum', 'Ekin Deniz Horzum']
        ],
        [
            'YÖNETMENLER', [
                'Şengül Halat Atak',
                'Kemal Uzun',
                'Bora Onur'
            ]
        ],
        [
            'Oyuncular', [
                'Pınar Altuğ',
                'Emre Kınay',
                'Sermin Hürmeriç',
                'Bilge Sen',
                'Yeşim Büber',
                'Aysen Gruda'
            ]
        ]
    ],
    'Eng': [
        [
            'Country', ['Turkey']
        ],
        [
            'Language', ['Turkish']
        ],
        [
            'Executive Producer', ['Sergin Akyaz']
        ],
        [
            'Created by', ['Sergin Akyaz']
        ],
        [
            'Writer group',  ['Yılmaz Sahin', 'Sema Ergenekon', 'Eylem Canpolat',
                'Gökhan Horzum', 'Ekin Deniz Horzum']
        ],
        [
            'Directors', [
                'Şengül Halat Atak',
                'Kemal Uzun',
                'Bora Onur'
            ]
        ],
        [
            'Cast', [
                'Pınar Altuğ',
                'Emre Kınay',
                'Sermin Hürmeriç',
                'Bilge Sen',
                'Yeşim Büber',
                'Aysen Gruda'
            ]
        ]
    ]
};