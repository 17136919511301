import  { detail } from './details'
import  { info, type } from './Info'
export const deryaDeniz = function(lang: any){
    return {
        name: 'Derya Deniz',
        photos: require('./Photos.ts').photos,
        infos: info[lang as keyof typeof info],
        details: detail[lang as keyof typeof detail],
        year: '2002',
        type: type[lang as keyof typeof type]
    }
}