export const info = {
    'TR': `Babasının intikamıyla yanıp tutuşan, 
    uçsuz bucaksız toprakların tek sahibi Doğan Bey 
    ile yoksul ama bir o kadar da zeki Alev’in ihtiras 
    ve entrika dolu aşk öyküsünü anlatır. Ama hikayenin tümü gibi 
    onların aşkı da bu lanetli topraklar üzerine kuruludur. İki güçlü 
    karakterin paylaşacakları aşk, paylaşamayacakları iktidar ve en çaresiz 
    anda hayatı tümden ve zincirleme değişen insanların bu ‘Ateşli Topraklar’ 
    üzerinde yaşadıkları hayatları şekillendirir...`,

    'Eng': `t’s the passionate and intriguing 
    story between Doğan and Alev. Doğan who is the 
    only owner of immense land, burns with revenge 
    for his father’s death. On the other hand, Alev is a 
    poor but very clever girl. Like this story, their 
    love is based on the damned land as well. 
    Two strong characters who cross paths in love, 
    but cross swords in power, are the ruler in this 
    fierce land. They change everybody’s life.`
};
export const type = {
    'TR': 'Dizi/ATV',
    'Eng': 'Tv-Series/ATV'
};