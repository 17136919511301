export const info = {
    'TR': `Gelin ve kaynananın ezeli mücadelesinin 
    eğlenceli ve aktüel bir dille anlatıldığı tv 
    programıdır.
    Her hafta birbirinden güzel üç gelin adayının, 
    başta yemek olmak üzere bütün hünerlerini bizlere 
    gösterdiği programda, ‘Erkeğin kalbine giden yol 
    midesinden geçer’ mantığıyla damat adayı ve annesi 
    birbirinden lezzetli yemekleri yiyerek hayallerindeki 
    gelin adayını bulmaya çalışır. Kazanan yarışmacılara 
    evlilik hayallerinin yanında büyük ödül de vaat eden 
    eğlenceli ve seyirlik programdır.`,

    'Eng': `Gelin ve kaynananın ezeli mücadelesinin 
    eğlenceli ve aktüel bir dille anlatıldığı tv 
    programıdır.
    Her hafta birbirinden güzel üç gelin adayının, 
    başta yemek olmak üzere bütün hünerlerini bizlere 
    gösterdiği programda, ‘Erkeğin kalbine giden yol 
    midesinden geçer’ mantığıyla damat adayı ve annesi 
    birbirinden lezzetli yemekleri yiyerek hayallerindeki 
    gelin adayını bulmaya çalışır. Kazanan yarışmacılara 
    evlilik hayallerinin yanında büyük ödül de vaat eden 
    eğlenceli ve seyirlik programdır.`
};
export const type = {
    'TR': 'Tv Programı/ATV',
    'Eng': 'Tv-Show/ATV'
};