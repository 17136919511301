export const info = {
    'TR': `Yeşilcam klasikleri tadında, Keloğlan masalları saflığında samimiyeti ve doğruluğu anlatan bir dizidir.
    Şehre yeni gelen ve köydeki sevgilisinden başka bir şey düşünmeyen genç bir adamın İstanbul’daki eğlenceli mücadelesini anlatır.`,

    'Eng': `The series which gives the taste of Yeşilçam classics, tells the story of sincerity and accuracy in a purity of Keloglan folk tales.
    The series is about a young man’s entertaining adventure in İstanbul who recently arrives the city and thinks nothing more than his lover in the village.`
};
export const type = {
    'TR': 'Dizi/Star TV',
    'Eng': 'Tv-Series/Star TV'
};